.footer {
  @include mq(">midWidth") {
    margin-top: 107px;
  }
  @include mq("<midWidth") {
    margin-top: 120px;
  }
  .commonInner__block, .commonInner_box{
    width: 100%;
  }

  &__inner {
    margin-left: auto;
    margin-right: auto;
    padding-top: 45px;
    padding-bottom: 40px;
    width: 100%;
    @include mq("<midWidth") {
      padding-top: 35px;
      padding-bottom: 38px;
    }

    position: relative;
    border-top: 1px solid $blue;

    @include mq(">maxWidth") {
      width: 100%;
      margin-right: 0;
    }
  }

  &__block {
    display: flex;
    @include mq("<maxWidth") {
      display: block;
    }
  }
  &__info {
    flex: 1;
    margin-right: 20px;
    @include mq(">minWidth") {
      min-width: 410px;
    }
    @include mq("<maxWidth") {
      width: 100%;
    }
  }
  &__email {
    margin-bottom: 16px;
    .footer__desc_text {
      @include underline;
      text-decoration-thickness: 1px;
    }
  } //__email
  &__tel {
    .footer__desc_text {
      @include underline;
      text-decoration-thickness: 1px;
      @include mq("<maxWidth") {
        @include underline;
        text-decoration-thickness: 1px;
      }
    }
  } //__tel
  &__blockaddress {
    @include mq(">midWidth") {
      margin-top: 35px;
    }
    @include mq("<midWidth") {
      margin-top: 45px;
    }
  }
  &__address {
    line-height: 22px;
    // margin-bottom: 30px;
    @include mq("<midWidth") {
      font-size: 14px;
      // margin-bottom: 32px;
    }
  }
  &__blocktext {
    flex: 1;
    margin-left: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq(">midWidth") {
      font-size: 16px;
    } //pc
  }
  &__blocktext_text{
    line-height: 27px;
    @media all and (max-width: 1200px){
      display: none;
    }
  }
  &__desc {
    display: flex;
    @include mq(">midWidth") {
      font-size: 20px;
    }
    @include mq("<midWidth") {
      font-size: 20px;
    }
  }
  &__desc_title {
    min-width: 97px;
  }
  &__company {
    @include mq(">midWidth") {
      margin-bottom: 10px;
    }
    @include mq("<midWidth") {
      margin-bottom: 10px;
      font-size: 14px;
    }
    &-branch {
      margin-top: 10px;
    }
  }
  &__scrolltop {
    width: 28px;
    position: absolute;
    left: calc(50% - 16px);
    @include mq(">midWidth") {
      top: -70px;
    }
    @include mq("<midWidth") {
      top: -42px;
    }
  }
  &_copy {
    color: $gray;
    font-size: 14px;
    margin-top: auto;
    text-align: right;
    @include mq("<maxWidth") {
      text-align: unset;
      margin-top: 20px;
    }
  }
}
.footer__mon{
  margin-top: 50px;
  .-title{
    font-size: 20px;
    margin-bottom: 16px;
  }
  .-text{
    margin-bottom: 10px;
    line-height: 22px;
    @include mq("<midWidth") {
      font-size: 14px;
    }
  }
  .mon__info{
    display: flex;
    @include mq("<midWidth") {
      font-size: 14px;
    }
    @include mq("<minWidth") {
      display: block;
    }
  }
  .-flexbox{
    @include mq(">minWidth") {
      margin-left: 20px;
    }
    @include mq("<minWidth") {
      margin-top: 10px;
    }
  }
}
