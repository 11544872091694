// media query break point
@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina":
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">contWidth": "(min-width: #{$mediaContWidth + 1})",
    "<contWidth": "(max-width: #{$mediaContWidth})",
    ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
    "<maxWidth": "(max-width: #{$mediaMaxWidth})",
    ">midWidth": "(min-width: #{$mediaMidWidth + 1})",
    "<midWidth": "(max-width: #{$mediaMidWidth})",
    ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
    "<minWidth": "(max-width: #{$mediaMinWidth})",
  );
  @return map-get($condMap, $c);
}

// media query
@mixin mq($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

// font-family
@mixin font_notosans {
  font-family: "Noto Sans JP", sans-serif;
}
@mixin font_roboto {
  font-family: "Roboto", sans-serif;
}

// TsukuGoPr5-R: fontplus.jp
// Avenir:
@mixin font_default {
  font-family: "AvenirLTPro-Light", "Avenir", "FOT-筑紫ゴシック Pro R",
    "TsukuGoPr5-R"
      /*, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, sans-serif*/;
}

@mixin font_tsukugo_R {
  font-family: "FOT-筑紫ゴシック Pro R", "TsukuGoPr5-R";
}

@mixin font_tsukugo_M {
  font-family: "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M";
}

@mixin font_tsukugo_D {
  font-family: "FOT-筑紫ゴシック Pr5 D", "TsukuGoPr5-D";
}
@mixin font_avenir_L {
  font-family: "AvenirLTPro-Light", "Avenir";
}
@mixin font_avenir_M {
  font-family: "AvenirNextLTPro-Medium";
}
@mixin font_avenir_B {
  font-family: "AvenirLTPro-Book", "Avenir";
}

// font-weight
@mixin notosans_bold {
  font-weight: 700;
}
@mixin roboto_bold {
  font-weight: 700;
}

// letter spacing
@mixin ls_01 {
  letter-spacing: 0.1em;
}
@mixin ls_02 {
  letter-spacing: 0.2em;
}

// line-height
@mixin lh_pc {
  @include mq(">midWidth") {
    line-height: 1.5;
  }
}
@mixin lh_sp {
  @include mq("<midWidth") {
    line-height: 1.65;
  }
}
// font-size and line-height
@mixin text_mainTitle {
  // font-size: 36px;
  font-size: 42px;
  @include mq("<midWidth") {
    font-size: 32px;
  }
}

@mixin text_subTitle {
  font-size: $fs_text_pc;
  line-height: $lh_text;
  @include mq("<midWidth") {
    font-size: 14px;
  }
}

@mixin text_lead {
  font-size: 20px;
  line-height: $lh_text;
}

@mixin text_default {
  font-size: $fs_text_pc;
  line-height: $lh_text;
  @include mq("<midWidth") {
    font-size: $fs_text_sp;
  }
}

@mixin text_small {
  font-size: 14px;
  line-height: $lh_text;
}

// underline
@mixin underline {
  text-decoration: underline;
}

// font-size
// PC
@mixin fontsize_rem($size, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}
// SP
@mixin fontsize_vw($size: 10) {
  font-size: $size * 1px;
  font-size: get_vw($size, 750);
}
// SP
@mixin fontsize_vh($size: 10) {
  font-size: $size * 1px;
  font-size: get_vh($size, 750);
}
//  px to vw
// ====================================================
@function get_vw($number, $viewport: 750) {
  $vw: $number / $viewport * 100;
  @return round-decimal($vw) + vw;
}
@function get_vh($number, $viewport: 750) {
  $vw: $number / $viewport * 100;
  @return round-decimal($vw) + vh;
}

@function get_per($number, $base) {
  $num: $number / $base;
  $round-num: round-decimal($num, 3);
  @return $round-num * 100%;
}
@function get_ratio($number, $base) {
  $num: $number / $base;
  $round-num: round-decimal($num, 3);
  @return $round-num;
}
@function get_kerning($number) {
  $num: $number / 1000;
  @return $num + em;
}
//  小数点の処理
// ====================================================
// 四捨五入
@function round-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, "round");
}
// 切り上げ
@function ceil-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, "ceil");
}
// 切り捨て
@function floor-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, "floor");
}
@function to-fixed($number, $digits: 0, $round: "round") {
  $n: 1;
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $round == "round" {
    @return round($number * $n) / $n;
  } @else if $round == "ceil" {
    @return ceil($number * $n) / $n;
  } @else if $round == "floor" {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $round } is undefined keyword.';
    @return $number;
  }
}
//  単位を外す
// ====================================================
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
