body[id="philosophy"], body[id="service"] {
  .viewWorks {
    display: inline-block;
    @include mq(">maxWidth") {
      margin-top: 30px;
    }
    @include mq("<maxWidth") {
      margin-top: 15px;
    }
  } //viewWorks
  .common__read_title {
    @include mq(">maxWidth") {
      margin-bottom: 28px;
    }
    @include mq("<maxWidth") {
      margin-bottom: 24px;
    }
  } //common__read_title
  .philosophy_section {
    &.-services {
      .philosophy_content__text {
        @include mq("<maxWidth") {
          margin-top: 20px;
        }
      }
      .common__title_block {
        @include mq(">maxWidth") {
          margin-bottom: 60px;
        }
      } //common__title_block
    } //services
  } //philosophy_section
}
