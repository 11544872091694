* {
  font-weight: normal;
}
html {
  font-size: 16px;
  line-height: 1;
  @include font_default;
  background-color: $gray-bg;
  color: $blue;
  letter-spacing: get_kerning(15);
}
// CSS：YakuHanJP
.yakuhanJp {
  font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN",
    "Noto Sans JP", Meiryo, sans-serif;
}

.-underline{
  text-decoration: underline;
}

.common__title_block {
  @include mq(">midWidth") {
    margin-bottom: 80px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  @include mq("<maxWidth") {
    margin-bottom: 30px;
  }
  &.-column {
    flex-direction: column;
  }
  &.-about {
    display: block;
  } //about
}
.common__txt_area {
  @include mq(">midWidth") {
    line-height: 1.5;
  }
  @include mq("<midWidth") {
    line-height: 1.5;
  }
  .-text{
    a {
      text-decoration: underline;
    }
  }
}
.common__left_block {
  @include mq(">maxWidth") {
    width: 28%;
  }
} //common__left_block
.common__right_block {
  @include mq(">maxWidth") {
    width: 69%;
    padding-left: 3%;
    // margin-left: 15%;
  }
}
.common__borderbtm_sp {
  &:not(:last-child) {
    @include mq("<maxWidth") {
      border-bottom: 1px solid $border-color;
    }
  }
  &.-section {
    &:not(:last-child) {
      @include mq("<maxWidth") {
        border-bottom: none;
        padding-bottom: 60px;
        margin-bottom: 60px;
      }
    }
  } //section
  &.-contents {
    &.-default {
      &:not(:last-child) {
        // @include mq(">maxWidth") {
        //   padding-bottom: 90px;
        // }
        @include mq("<maxWidth") {
          padding-bottom: 40px;
        }
      }
    } //default
    &.-small {
      &:not(:last-child) {
        // @include mq(">maxWidth") {
        //   padding-bottom: 40px;
        // }
        @include mq("<maxWidth") {
          padding-bottom: 40px;
        }
      }
    } //small
  } //contents
} //common__borderbtm
.common__layout_block {
  &.-section {
    &:not(:last-child) {
      @include mq(">maxWidth") {
        margin-bottom: 150px;
      }
    }
  } //section
  &.-contents {
    &.-default {
      &:not(:last-child) {
        @include mq(">maxWidth") {
          margin-bottom: 90px;
        }
        @include mq("<maxWidth") {
          margin-bottom: 40px;
        }
      }
    } //default
    &.-small {
      &:not(:last-child) {
        @include mq(">maxWidth") {
          margin-bottom: 80px;
        }
        @include mq("<maxWidth") {
          margin-bottom: 40px;
        }
      }
    } //small
    .common__title_wrap {
      @include mq("<maxWidth") {
        margin-bottom: 2rem;
      }
    }
  } //contents
} //common__layout_block
.common__intro {
  @include mq(">maxWidth") {
    margin-bottom: 60px;
  }
  @include mq("<maxWidth") {
    margin-bottom: 30px;
  }
}
.common__main_title {
  @include text_mainTitle;
}
.common__info {
  &_item {
    display: flex;
    align-items: baseline;
    &.-recruit {
      @include mq("<maxWidth") {
        flex-direction: column;
      } //sp
    } //recruit
    &:not(:last-child) {
      margin-bottom: 10px;
      @include mq("<maxWidth") {
        margin-bottom: 20px;
      } //sp
    }
  } //_item
  &_item_head {
    color: $gray;
    @include font_tsukugo_M;
    @include mq("<maxWidth") {
      font-size: 14px;
    }
    &.-w90_pc {
      @include mq(">maxWidth") {
        min-width: 90px;
        margin-right: 10px;
      }
    } //-w90_pc
    &.-w100_pc {
      @include mq(">maxWidth") {
        min-width: 90px;
        margin-right: 10px;
      }
    } //-w100_pc

    &.-w120_pc {
      @include mq(">maxWidth") {
        min-width: 170px;
      }
    } //-w120_pc
    &.-w60_sp {
      @include mq("<maxWidth") {
        min-width: 60px;
      }
    } //-w60_sp
    &.-w90_sp {
      @include mq("<maxWidth") {
        min-width: 90px;
      }
    } //-w90_sp
    @include mq("<maxWidth") {
      margin-bottom: 10px;
    } //sp
  } //_item_head
  &_item_txt {
    @include lh_pc;
    @include lh_sp;
    @include mq("<maxWidth") {
      margin-top: -0.4em;
    }
  } //_item_txt
} //common__info

.common__info_item_txt .-link, .footer__address .-link{
  font-size: 0.8em;
}

body {
  @include mq(">midWidth") {
    padding-top: 76px;
  }
  @include mq("<midWidth") {
    padding-top: 55px;
  }
}
img {
  display: block;
  width: 100%;
  height: auto;
}
// sp, pc
.is_pc {
  @include mq("<midWidth") {
    display: none;
  }
}
.is_sp {
  @include mq(">midWidth") {
    display: none;
  }
}
// display
.-pcItem {
  @include mq(">midWidth") {
    display: inherit;
  }
  @include mq("<midWidth") {
    display: none !important;
  }
}
.-spItem {
  @include mq(">midWidth") {
    display: none !important;
  }
  @include mq("<midWidth") {
    display: inherit;
  }
}
.-displayNon {
  display: none !important;
}

.fs80per {
  font-size: 80%;
}

// common
.common {
  &__link_block--center {
    text-align: center;
  }
  &__link {
    // @include underline;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100%;
    &_block--center {
      width: 100%;
    } //_block--center
  }
  &__border_bot--blue {
    border-bottom: 1px solid $blue;
  }
  &__all_top_block {
    margin-top: 55px;
    @include mq("<minWidth") {
      margin-top: 44px;
      // margin-bottom: 10px;
    }
  }
  &__all_category_list {
    display: flex;
    flex-wrap: wrap;
    @include mq(">midWidth") {
      margin-top: 20px;
      margin-bottom: (72-10) + px;
    }
    @include mq("<midWidth") {
      margin-top: 18px;
      margin-bottom: 48px;
    } //sp

    :not(:last-child)::after {
      @include mq(">midWidth") {
        content: "/";
        padding: 0 15px;
        color: $blue;
      }
    }
    :not(:last-child)::after {
      @include mq("<midWidth") {
        content: "/";
        padding: 0 7px;
        color: $blue;
      }
    }
  }
  &__all_category_each {
    margin-bottom: 10px;
  }
  &__all_category_link {
    color: $gray;
    &.is_current {
      color: $blue;
    }
  }

  // common__title
  &__title_block {
    // margin-bottom: 68px;
  }
  &__main_title {
    margin-bottom: 8px;
    @include mq("<midWidth") {
      // @include font_avenir_M;
      margin-bottom: 8px;
    } //sp
    &.-about {
      margin-bottom: 4px;
    } //about
    &.-titleLevel01 {
      @include text_mainTitle;
      font-weight: lighter;
      @include mq("<midWidth") {
        font-size: 36px;
      }
      &.-ja {
        font-size: 32px;
        @include font_tsukugo_D;
      }
      &.-row {
        @include mq(">midWidth") {
          margin-right: 16px;
        } //pc
      } //row
    }
    &.-titleLevel02 {
      font-size: 28px;
      @include mq("<midWidth") {
        font-size: 28px;
      }
      &.-ja {
        font-size: 22px;
        @include font_tsukugo_D;
        @include mq("<midWidth") {
          font-size: 22px;
        }
      } //-ja
    }
  } //__main_title
  &__read_title {
    @include lh_pc;
    @include lh_sp;
    @include mq("<maxWidth") {
      font-size: 19px;
    }
    @include mq(">maxWidth") {
      font-size: 20px;
    }
    &.-ja {
      @include font_tsukugo_D;
    }
    &.-adjustEm {
      margin-top: -0.2em;
    } //adjustEm
  }
  &__sub_title {
    @include text_subTitle;
    @include font_tsukugo_D;
    color: $gray;
  }

  &_flex__block {
    @include mq(">maxWidth") {
      display: flex;
    }
  } //flex__block
  &__title_block {
    @include mq(">maxWidth") {
      // min-width: get_per(304, 965);
      // margin-right: get_per(70, 965);
      // margin-bottom: unset;
    }
    @include mq("<maxWidth", ">midWidth") {
      // margin-right: auto;
    }
  } //__title_block
  // form
  &__form {
    // iphone用のスタイル削除
    input[type="button"],
    input[type="text"],
    input[type="submit"],
    input[type="image"],
    textarea {
      -webkit-appearance: none;
      border-radius: 0;
    }
    // フォーカス時の設定
    input[type="text"]:focus,
    textarea:focus {
      // outline: solid 2px white;
      outline: none !important;
    }
    input[type="text"] {
      height: 46px;
    }
    textarea {
      height: 232px;
      line-height: 1.5;
      @include mq("<midWidth") {
        height: 244px;
      }
    }
    input {
      background-color: #fff;
      color: $blue;
    }
    input:-webkit-autofill {
      background-color: #fff !important;
      color: $blue !important;
      box-shadow: 0 0 0px 999px #fff inset;
      transition: background-color 10000s ease-in-out 0s;
    }
    input[type="text"],
    textarea {
      background-color: #fff !important;
      // color: $gray;
      color: $blue !important;
      padding: 15px;
      @include mq("<midWidth") {
        background-color: white;
        // font-size: 14px;
      }
    }
    input[type="text"]::placeholder,
    textarea::placeholder {
      color: $gray;
      @include font_tsukugo_R;
    }
    .-error {
      margin-top: 7px;
      font-size: 12px;
      color: #bf0000;
    }
  }
  &__form_list {
    position: relative;
  }
  &__form_list_each {
    padding: 20px 0 0;
    position: relative;
    .common__form_extra {
      margin-top: 7px;
    }
  }
  &__form_list_each--multiple {
    display: flex;
    .common__form_list_title {
      padding-top: 0;
    }
    .common__form_inner_title {
      padding-top: 9px;
    }
  }
  &__form_list_title {
    color: $gray;
    min-width: 130px;
    @include mq("<midWidth") {
      font-size: 14px;
    }
  }
  &__form_list_inner,
  &__form_inner_each,
  &__form_list_item,
  &__form_input,
  &__form_textarea {
    width: 100%;
  }
  &__form_list_inner {
    @include mq(">midWidth") {
      max-width: 526px;
      margin-left: auto;
    }
  }
  &__form_inner_each {
    display: flex;
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
  &__form_list_item {
    display: flex;
    flex-direction: column;
    p {
      line-height: 1.5;
    }
    .common__form_list_item_head {
      color: #a3a3a3;
      margin-bottom: 6px;
      &.-required {
        position: relative;
        &::before {
          content: "*";
        } //before
      } //-required
    } //common__form_list_item_head
  }
  &__form_input,
  &__form_textarea {
    background-color: transparent;
    border: none;
  }
  &__form_inner_title {
    min-width: 100px;
    color: $gray;
    font-size: 12px;
  }
  &__form_extra {
    font-size: 12px;
    display: block;
    text-align: end;
    color: #a3a3a3;
    margin-bottom: (17 - 20) + px;
    a {
      text-decoration: underline;
    }
  }
  &__form_button_box {
    margin-top: 40px;
    position: relative;
    display: flex;
  }
  &__form_button {
    width: 100%;
    height: 48px;
    color: #fff !important;
    background-color: $blue !important;
    border: none;
    display: table;
    text-align: center;
    cursor: pointer;
    &:nth-child(1) {
      margin-left: auto;
    }
    &:nth-child(2) {
      margin-left: 8px;
    }
    p {
      display: table-cell;
      vertical-align: middle;
      font-weight: lighter;
    }
    &.-bgWhite {
      color: $blue !important;
      background-color: #fff !important;
    } //-bgWhite
  }
  // js animation
  &__js_animate {
  }
  &__js_nav_animate {
  }
  &__js_click_animate {
  }
  &__js_fadein--left {
    @include mq(">midWidth") {
      opacity: 0;
      visibility: hidden;
      transform: translateX(10%);
      transition: transform 0.6s, opacity 0.6s ease-out;
    }
    &.is_animate {
      @include mq(">midWidth") {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
        transition: transform 0.6s, opacity 0.6s ease-out;
      }
    }
  }
  &__js_fadein--right {
    @include mq(">midWidth") {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-5%);
      transition: transform 0.6s, opacity 0.6s ease-out;
    }
    &.is_animate {
      @include mq(">midWidth") {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
        transition: transform 0.6s, opacity 0.6s ease-out;
      }
    }
  }
  &__js_fadein--up {
    @include mq(">midWidth") {
      opacity: 0;
      visibility: hidden;
      transform: translateY(10%);
      transition: transform 0.6s, opacity 0.6s ease-out;
    }
    &.is_animate {
      @include mq(">midWidth") {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
        transition: transform 0.6s, opacity 0.6s ease-out;
      }
    }
  }
  &__js_translate--up {
    @include mq(">midWidth") {
      transform: translateY(10%);
      transition: transform 0.6s ease-out;
    }
    &.is_animate {
      @include mq(">midWidth") {
        transform: translateY(0%);
        transition: transform 0.6s ease-out;
      }
    }
  }
}

// .main
.main,
.footer {
  max-width: 1800px;
  margin-right: auto;
  margin-left: auto;
  @include mq(">midWidth") {
    margin-top: 21.5px;
    padding: 0 $side_margin_pc;
  }
}
.l-inner {
  margin-left: auto;
  margin-right: auto;
  // @include mq("<maxWidth", ">midWidth") {
    // padding: 0 $side_margin_mid;
  // }
  @include mq("<midWidth") {
    padding: 0 $side_margin_sp;
  }
}

.main_inner {
  @include mq(">midWidth") {
    margin-left: $guide_ratio;
  }
}
//pcの時は電話番号タップしてもリンク設定なし
@include mq(">midWidth") {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
.commonInner__block {
  display: flex;
  flex-wrap: wrap;
} //commonInner__block
.commonInner_box {
  @include mq(">maxWidth") {
    margin-left: $guide_ratio;
  }
} //commonInner_box

.mon__info{
  .-link{
    display: flex;
  }
  .-icon{
    width: 1em;
    height: 1em;
    margin-left: 5px;
  }
  .-flexbox{
    display: flex;
    .-link{
      &:nth-child(2){
        margin-left: 15px;
      }
    }
  }
}

#error_404 .main{
  min-height: 23vh;
}