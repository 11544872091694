.u-anchor {
  position: absolute;
  @include mq(">midWidth") {
    top: -80px;
  }
  @include mq("<midWidth") {
    top: -70px;
  }
}

.u-mt1 {
  margin-top: 10px;
}
