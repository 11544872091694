.blog_content {
  @include mq(">midWidth") {
    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
  @include mq("<midWidth") {
    margin-top: 35px;
    &:not(:last-child) {
      margin-bottom: 98px;
    }
  }
  &__article_block {
    @include mq(">midWidth") {
      // margin-bottom: 175px;
    }
    @include mq("<midWidth") {
      // margin-bottom: 98px;
    }
    &:not(:last-of-type) {
      @include mq(">midWidth") {
        margin-bottom: 65px;
        border-bottom: 1px solid $blue;
        .blog_content__article_main {
          margin-bottom: 100px;
        }
      }
      @include mq("<midWidth") {
        margin-bottom: 98px;
      }
    }
  }
  &__article_top {
    @include mq(">midWidth") {
      margin-left: $guide_ratio;
      // margin-left: $top_left_pc;
      margin-bottom: 70px;
    }
    @include mq("<midWidth") {
      margin-bottom: 70px;
    }
  }
  &__back_block {
    display: inline-block;
    // margin-bottom: 35px;
    margin-top: 30px;
  }
  &__back_link {
    color: $gray;
    font-size: 16px;
    display: flex;
    line-height: 28px;
    position: relative;
    margin-left: (20+15) + px;
    &::before {
      content: "";
      background-image: url(/assets/front/img/common/arrow_left_gray.svg);
      display: inline-flex;
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -(20+15) + px;
    }
  }
  &__article_info {
    display: flex;
    margin-bottom: 20px;
  }
  &__date {
    color: $gray;
    @include mq("<midWidth") {
      // padding-right: 27px;
    }
    &::after {
      content: "/";
      padding: 0 10px;
      color: $gray;
    }
  }
  &__cat {
    color: $gray;
  }
  &__article_title {
    @include mq(">midWidth") {
      font-size: 27px;
    }
    @include mq("<midWidth") {
      font-size: 20px;
    }
  }
  &__article_main {
    @include mq(">midWidth") {
      // margin-left: 220px;
      // max-width: 850px;
      // margin-left: $guide_ratio;
    }
  }
  &__more_link {
    @include mq(">midWidth") {
      display: none;
    }
    @include mq("<midWidth") {
      display: block;
      text-decoration: underline;
      text-align: center;
    }
  }
}
.top_index, .blog_index{
  .common__item_list__eachimg{
    height: 0;
    padding-top: get_per(49,80);
    background-size: cover;
    background-position: center;
  }
}