body[id="works"] {
  // 共通記述
  .works_content {
    .common {
      &__all_category_list {
        width: auto;
        font-size: 16px;
        &.-category{
          // margin-bottom: 20px;
        }
      }
      &__all_category_link {
        color: $gray;
        &.is_current {
          color: $blue;
        }
      }
      &__all_category_each {
        margin-bottom: 0;
      }
    }

    &__link_external {
      &::after {
        content: url(/assets/front/img/common/arrow_link_gray.svg);
        display: inline-block;
        width: 10px;
        height: 10px;
      }
    }
  }
  &[class="works_detail"] {
    // 共通記述
    .works_content {
      .common {
        &__all_category_link {
          color: $blue;
        }
      }
    }
    .common__main_title.-ja {
      line-height: 1.3;
    } //common__main_title
    .common__main_title.-titleLevel02 {
      color: $gray_2;
    }
    .mainCaption {
      text-align: right;
      margin-top: 10px;
      font-size: 14px;
    } //mainCaption
    .common__all_category_list {
      margin-bottom: 60px;
    }

    .works_content {
      &__mainImg {
        margin-bottom: 50px;
      } //__mainImg
      &__top_block {
        @include mq(">midWidth") {
          margin-bottom: 220px;
        }
        @include mq("<midWidth") {
          margin-bottom: 70px;
        }
      } //__top_block
    }

    // 詳細画像
    .works_content {
      &__img_block {
        margin-bottom: 32px;
        @include mq(">midWidth") {
          display: flex;
        }
        .works_content__img_each:nth-child(2) {
          @include mq(">midWidth") {
            margin-left: 14px;
          }
          @include mq("<midWidth") {
            margin-top: 32px;
          }
        }
      }
      &__img_each--100 {
        width: 100%;
      }
      &__img_each--50 {
        @include mq(">midWidth") {
          width: calc((100% - 14px) / 2);
        }
      }
      &__img_each--40 {
        @include mq(">midWidth") {
          width: 41%;
        }
      }
      &__img_each--60 {
        @include mq(">midWidth") {
          width: 57.84%;
        }
      }
    } //works_content

    // 下部リスト
    .works_content {
      &__main_block {
        @include mq(">midWidth") {
          margin-bottom: 100px;
        }
        @include mq("<midWidth") {
          margin-bottom: 60px;
        }
      }
      &__main_url {
        color: $gray;
        font-size: 18px;
        display: block;
        margin-top: 0.5rem;
        &.works_content__link_external {
          position: relative;
          padding-right: 20px;
          display: inline-block;
          &::after {
            position: absolute;
            right: 0;
            top: calc(50% - 10px);
            width: 10px;
            height: 1em;
          }
        }
      }
      &__main_title {
        line-height: 1.3;
      }
      &__titleUrlWrap {
        @include mq("<maxWidth") {
          margin-bottom: 2rem;
        }
      }
      &__leftRightWrap {
        display: flex;
        width: 100%;
        @include mq("<maxWidth") {
          display: block;
        }
      }
      &__left {
        @include mq(">maxWidth") {
          width: 40%;
        }
      }
      &__right {
        @include mq(">maxWidth") {
          width: 60%;
          padding-left: 10%;
        }
      }
      &__list_block {
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .-text{
          font-size: 14px;
          line-height: 1.4;
          transform: translateX(-3px);
        }
      }
      &__list_each {
        @include mq(">midWidth") {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 9px;
          }
        }
        @include mq("<midWidth") {
          width: 100%;
          display: flex;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
        &:nth-last-of-type(1) {
          .works_content__list_title,
          .works_content__list_detail {
            @include mq("<midWidth") {
              margin-bottom: 0;
            }
          }
        }
      }
      &__list_title {
        font-size: 12px;
        color: $gray;
        width: 120px;
        margin-top: 6px;
      }
      &__list_detail {
        font-size: 14px;
        flex: 1;
        line-height: 1.4;
      }
      &__list_link {
        &--ul {
          text-decoration: underline;
        }
        &.works_content__link_external {
          display: inline-flex;
          &::after {
            display: block;
            margin-left: auto;
            @include mq(">midWidth") {
              margin-left: 0.8rem;
            }
            @include mq("<midWidth") {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .works_list > .works_list__each {
        margin-bottom: 0;
      }
    }
  } //works_detail
} //works
