.top_index {
  overflow-x: hidden;
  .main {
    @include mq("<midWidth") {
      margin-top: 72px;
    }
  }
}

//**** spの時headerの高さ100vh設定
@include mq("<midWidth") {
  .top_index {
    padding-top: 0;
  }
  .headerFvWrap {
    .top_fv__block {
      height: 100%;
      a {
        display: block;
        height: 100%;
        position: relative;
        @include mq("<midWidth") {
          // height: auto;
          // display: flex;
          // flex-direction: column;
          // justify-content: end;
        }
      }
    }
    .top_fv__img {
      @include mq("<midWidth") {
        width: 100%;
        height: 100%;
      } //sp
    }
    .top_fv__textBox {
      @include mq("<midWidth") {
        padding-bottom: 36px;
        width: 100%;
        margin-top: auto;
      } //sp
    }
  } //headerFvWrap
}
//****
body {
  &.top_index {
    padding-top: 0;
    .header__left_list {
      @include mq(">midWidth") {
        padding-top: 76px;
      }
      @include mq("<midWidth") {
        padding-top: 52px;
      }
    } //header__left_list
  } //top_index
} //body
.top_fv {
  // .mainのレイアウト設定
  margin-right: auto;
  height: 100vh;
  padding: $fvPadding + px;
  @include mq("<midWidth") {
    margin: 76px auto 0;
    // .mainのレイアウト設定
    padding: 0 $side_margin_sp;
    padding-top: 55px;
    height: 100%;
    margin-top: 0;
  }

  &__block {
    position: relative;
    @include mq(">midWidth") {
      height: 100%;
    }
    @include mq("<midWidth") {
      margin-right: -20px;
    }
    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.6s;
      }
    }
    a {
      position: relative;
      @include mq(">midWidth") {
        height: 100%;
      }
    } //a
  }
  &__img {
    @include mq(">midWidth") {
      height: 100%;
    }
    @include mq("<midWidth") {
      width: 100%;
    }
    .slick-list, .slick-track{
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include mq("<midWidth") {
        height: auto;
        object-fit: contain;
        margin: auto;
        object-position: right;
      }
    }
    .top_fv__text{
      span:last-child{
        display: none;
      }
    }
  } 
  &__text {
    color: $gray;
  }
  &__bgtext {
    color: $gray;
  }
  &__textBox {
    @include mq(">midWidth") {
      position: absolute;
      bottom: 20px;
      margin-left: (34 - $fvPadding) + px;
    }
  } //Box
}

.top_mainText {
  @include mq(">midWidth") {
    margin-left: $guide_ratio;
    margin-bottom: 108px;
    margin-top: (110 + 15) + px;
  } //pc
  @include mq("<midWidth") {
    margin-bottom: 71px;
    margin-top: 44px;
  } //sp

  &__title {
    font-size: 19px;
    margin-bottom: 37px;
    @include mq("<midWidth") {
      margin-bottom: 32px;
    }
  }
  &__text {
    @include mq(">midWidth") {
      margin-bottom: 7px;
    }
    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.1s;
      }
    }
  }
  &__link {
    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.2s;
      }
    }
    span {
      color: $gray;
      margin-right: 9px;
    }
    a {
      @include underline;
    }
  }
}

.top_works {
  @include mq(">midWidth") {
    margin-bottom: 100px;
  }
  @include mq("<midWidth") {
    padding-bottom: 78px;
  }
  &__block {
    display: flex;
    flex-wrap: wrap;
  }
  &__block > &__each {
    &:nth-child(1) {
      width: get_per(958, 1282);
      margin-left: $guide_ratio;
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 143px;
      }
    }
    &:nth-child(2) {
      width: get_per(634, 1282);
      @include mq(">maxWidth") {
        margin-bottom: 116px;
      }
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 133px;
      }
    }
    &:nth-child(3) {
      width: get_per(418, 1282);
      margin-left: 15px;
      transition-delay: 0.1s;
      @include mq(">maxWidth") {
        margin-bottom: 116px;
      }
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 133px;
      }
    }
    &:nth-child(4) {
      width: get_per(634, 1282);
      margin-left: $guide_ratio;
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 143px;
      }
    }
    &:nth-child(5) {
      width: get_per(310, 1282);
      transition-delay: 0.1s;
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 143px;
      }
      @include mq(">midWidth") {
        padding-left: 15px;
      }
    }
    &:nth-child(6) {
      width: $guide_ratio;
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 147px;
      }
      @include mq(">midWidth") {
        padding-right: 15px;
      }
    }
    &:nth-child(7) {
      width: get_per(742, 1282);
      transition-delay: 0.1s;
      @include mq("<maxWidth", ">midWidth") {
        margin-bottom: 147px;
      }
    }
    &:nth-child(n) {
      @include mq("<midWidth") {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 37px;
      }
    }
    .top_works__eachimg{
      .-background{
        @include mq("<midWidth") {
          height: 0;
          padding-top: get_per(112,181);
          background-size: cover;
          background-position: center;
        } 
      }
    }
    // &.top_works__each--h {
      // .top_works__eachimg {
        // @include mq("<midWidth") {
          // margin-right: -20px;
          // margin-left: -20px;
          // width: calc(100% + 40px);
        // }
      // }
    // }
    .works_list__eachtitle {
      margin-top: 16px;
    } //works_list__eachtitle
    @include mq(">maxWidth") {
      margin-bottom: (138 - 40) + px;
    }
  }
  &__bottom {
    width: 100%;
    @include mq(">maxWidth") {
      margin-left: $guide_ratio;
    }
    &.works_list {
      @include mq("<midWidth") {
        display: none;
      }
    }
  }
  &__blocklink {
    font-size: 16px;
    @include mq(">maxWidth") {
      margin-top: (56 - 40) + px;
    }

    @include mq("<maxWidth", ">midWidth") {
      margin-bottom: 48px;
    }
  }
}

.works_list {
  @include mq(">minWidth") {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  & > &__each {
    margin-bottom: 37px;
    @include mq(">minWidth") {
      width: calc(100% / 3 - 10px);
    }
    &:nth-child(3n + 2) {
      @include mq(">minWidth") {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  &__eachtext,
  &__eachtitle {
    line-height: 1.5;
  }
  &__eachtext {
    color: $gray;
  }
  &__eachtitle {
    margin-top: 16px;
  }
  .works_list__eachimg{
    img{
      &.-pc{
        @include mq("<minWidth") {
          display: none;
        }
      }
      &.-sp{
        @include mq(">minWidth") {
          display: none;
        }
      }
    }
  }
}

.works_detail {
  .works_list {
    @include mq("<midWidth") {
      display: flex;
      flex-wrap: wrap;
      & > .works_list__each {
        width: calc((100% - 10px) / 2);
        &:nth-child(2n) {
          margin-left: 10px;
          margin-right: 0;
        }
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }
}
.top_news__maintitle {
  @include mq(">midWidth") {
    margin-bottom: 4.4rem;
  } //pc
  @include mq("<midWidth") {
    margin-bottom: 36px;
  } //sp
}
.intro__more_linkBox {
  display: flex;
  align-items: center;
  margin-top: (15+20) + px;
  .intro__more_link {
    @include mq(">midWidth") {
      margin-left: 8px;
    } //pc
    @include mq("<midWidth") {
      margin-left: 7px;
    } //sp
  } //intro__more_link
} //intro__more_linkBox
.intro__more_link {
  color: $blue;
  display: inline-block;
  @include underline;
} //intro__more_link
.intro__more_linkTxt {
  color: $gray;
} //intro__more_linkTxt
.top_latest {
  @include mq(">maxWidth") {
    margin-left: $guide_ratio;
  }
  @include mq(">midWidth") {
    margin-bottom: 120px;
  } //pc
  @include mq("<midWidth") {
    margin-bottom: 64px;
  } //sp

  .top_latest__maintitle {
    @include mq(">midWidth") {
      font-size: 20px;
      margin-bottom: 22px;
    } //pc
    @include mq("<midWidth") {
      font-size: 22px;
      margin-bottom: 34px;
    } //sp
  } //top_latest__maintitle
  .latestItemLink {
    display: flex;
    align-items: baseline;
    @include mq("<midWidth") {
      flex-direction: column;
    } //sp
  } //latestItemLink
  .common__item_list__eachimg {
    display: none;
  } //common__item_list__eachimg
  .common__item_list__eachtext {
    @include mq(">midWidth") {
      margin-top: 0;
    } //pc
  } //.common__item_list__eachtext
  .common__item_list__eachdate {
    margin-right: 20px;
    @include mq(">midWidth") {
      margin-top: 0;
    } //pc
  } //common__item_list__eachdate
  .common__item_list {
    flex-direction: column;
  } //.common__item_list
  .common__item {
    &:not(:last-child) {
      @include mq(">midWidth") {
        margin-bottom: 16px;
      } //pc
      @include mq("<midWidth") {
        margin-bottom: 23px;
      } //sp
    } //:not(:last-child) {
    &:last-child {
      margin-bottom: 0;
    } //:last-child
  } //.common__item
} //top_latest

.top_news {
  width: 100%;
  @include mq(">maxWidth") {
    // padding-left: $guide_ratio;
  }
  @include mq(">maxWidth") {
    // max-width: 1060px;
    width: 100%;
    margin-left: auto;
    // margin-left: -7.5px;
    // margin-right: -7.5px;
  }

  @include mq("<midWidth") {
    margin-bottom: 60px;
  }
  @include mq(">midWidth") {
    // margin-left: $guide_ratio;
  }

  .commonInner_box{
    width: 100%;
  }

  .common__item_list {
    @include mq("<midWidth") {
      box-sizing: border-box;
      flex-wrap: wrap;
      display: flex;
      margin: ((-28/2) + px) ((-20/2) + px);
    } //sp
  } //common__item_list
  .common__item {
    @include mq("<midWidth") {
      box-sizing: border-box;
      width: calc(100% / 2);
      padding: ((28/2) + px) ((20/2) + px);
      margin: unset !important;
    } //sp
  } //common__item
  @include mq("<midWidth") {
    .common__item:nth-child(n + 5) {
      display: none;
    }
  }
  .newsLine {
    border-top: 1px solid #bbb;
    padding-top: 30px;
  }
  .top_news__blocklink {
    @include mq(">midWidth") {
      margin-top: (56 - 40) + px;
      padding-left: 7.5px;
      padding-right: 7.5px;
    } //pc
    @include mq("<midWidth") {
      margin: 18px 10px 0;
    } //sp
  } //top_news__blocklink
}
