// 記事詳細 共通
.detail{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div{
    width: 100%;
    margin-bottom: 64px;
    @include mq("<midWidth") {
      margin-bottom: 32px;
    }
  }
  .detail__img--50{
    display: inline-block;
    width: calc((100% - 14px) / 2);
    @include mq("<midWidth") {
      width: calc((100% - 10px) / 2);
    }
  }
  .detail__img--40{
    display: inline-block;
    @include mq(">midWidth") {
      width: calc((100% - 14px) * 526 / ( 526 + 742 ) );
    }
  }
  .detail__img--60{
    display: inline-block;
    @include mq(">midWidth") {
      width: calc((100% - 14px) * 742 / ( 526 + 742 ) );
    }
  }
  .detail__img{
    @include mq("<midWidth") {
      margin-bottom: 20px;
    }
  }
  .detail__link{
    .hpLink {
      text-decoration: underline;
    } //hpLink
  }
  .-caption{
    margin-top: 7px;
    line-height: 1.5;
    font-size: 14px;
    @include mq("<midWidth") {
      font-size: 12px;
    }
  }
}
// 記事詳細 works用
.works_detail{
  .detail{
    &.common__layout_block.-contents.-small:not(:last-child){
      @include mq(">maxWidth") {
      margin-bottom: 38px;
      }
    }
    .common__main_title{
      width: 100%;
    }
    > div{
      margin-bottom: 32px;
    }
    .detail__img{
      @include mq("<midWidth") {
        width: 100%;
      }
    }
  }
  .detail__img.-video{
    position: relative;
    display: block;
    height: auto;
    padding-bottom: 56.25%;
  }
  .detail__img.-video iframe{
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}