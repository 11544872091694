.header {
  width: 100%;
  &.is_nav_open {
    .header__nav {
      //fadein
      opacity: 1;
      visibility: visible;
      transform: translateX(0%);
      transition: transform 0.3s, opacity 0.3s;
      @include mq(">midWidth") {
        overflow-y: scroll;
      }
    }
    .header__top {
      background-color: unset !important;
    }
  }
  &__top {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    @include mq(">midWidth") {
      height: 76px;
    }
    @include mq("<midWidth") {
      height: 55px;
      // ★
      // background-color: red;
      background-color: rgba($gray-bg, 0.95);
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    @include mq(">midWidth") {
      transform: translateX(-100px);
      margin-top: 30px;
      margin-left: 34px;
      height: 23.56px;
    }
    @include mq("<midWidth") {
      // padding-top: 20px;★
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 20px;
    } //sp
    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.6s;
      }
    }
  }
  &__logoimg {
    @include mq(">midWidth") {
      width: 23.19px;
      height: 23.19px;
    } //pc
    @include mq("<midWidth") {
      width: 18.55px;
      height: 18.55px;
    } //sp
  }
  &__logotext {
    @include mq(">midWidth") {
      height: 18.56px;
      width: 135.26px;
      margin-top: 5px;
      margin-left: 6.6px;
    } //pc
    @include mq("<midWidth") {
      height: 14.85px;
      width: 108.21px;
      margin-top: 4px;
      margin-left: 5.2px;
    } //sp
  }
  &__navbtn {
    height: 100%;
    display: block;
    margin-left: auto;
    cursor: pointer;
    @include mq(">midWidth") {
      width: 86px;
      padding: 31.5px 28px 31.5px;
    }
    @include mq("<midWidth") {
      width: 44px;
      padding: 19.5px 7px 19.5px;
      right: 20px;
      position: relative;
      // top: 5px;★
    }
  }

  &__btnparts {
    display: block;
    width: 100%;
    height: 1px;
    position: relative;
    background-color: $blue;
    transition: 0.3s;
    transition-property: transform;
  }
  &__btnparts--mid {
    top: 5px;
  }
  &__btnparts--before {
    top: 0px;
  }
  &__btnparts--after {
    top: 10px;
  }

  // nav
  &__nav {
    background-color: rgba(163, 163, 163, 0.9);
    position: fixed;
    top: 0;
    right: 0;
    width: 480px;
    color: $white;
    height: 100%;
    z-index: 100;
    //fadein
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    transition: all 0.3s;

    @include mq("<midWidth") {
      width: 100%;
    }
    .header__btnparts {
      background-color: $white;
    }
    .header__btnparts--before {
      margin-bottom: unset;
      top: 6px;
      transform: rotate(22deg);
    }
    .header__btnparts--mid {
      display: none;
    }
    .header__btnparts--after {
      margin-top: unset;
      top: 5px;
      transform: rotate(-22deg);
    }
  }
  &__nav_link_block {
    margin-left: 55px;
    @include mq(">midWidth") {
      margin-top: 90px;
    }
    @include mq("<midWidth") {
      margin-left: $side_margin_sp;
      margin-left: 28px;
      overflow: scroll;
      height: 100%;
      padding-bottom: 140px;
      margin-top: 111px;
    }
  }
  &__nav_link_each {
    margin-bottom: 28px;
    font-size: 32px;
    @include mq("<midWidth") {
      font-size: 28px;
      .header__nav_link_block > &:nth-child(1) {
        font-size: 28px;
        // margin-bottom: 28px;
      }
      .header__nav_link_block > &:nth-child(3) {
        // margin-bottom: 30px;
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      } //:not(:last-child) {
      &:last-child {
        margin-bottom: 0;
      } //:last-child
    }
    &.-mt {
      margin-top: 20px;
    }
    &.-office {
      font-size: 18px;
    }
  }
  &__nav_link {
    display: inline-block;
    @include mq("<midWidth") {
      font-weight: lighter;
    }

    &:hover {
      opacity: 0.6;
    }
    &.is_current {
      &::before {
        content: "";
        display: block;
        width: calc(100% + 14px);
        height: 1px;
        background-color: $blue;
        transform: translateY(0.5em);
      }
    }
  }
  &__nav_link_inner {
    display: flex;
    margin-left: 25px;
    @include mq(">midWidth") {
      margin-bottom: 16px;
    }
    @include mq("<midWidth") {
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      @include mq(">midWidth") {
        margin-top: 24px;
      }
      @include mq("<midWidth") {
        margin-top: 20px;
      }
    }
    .header__nav_link {
      font-size: 24px;
      @include mq("<midWidth") {
        font-size: 20px;
      }
    }
    .header__nav_link_span {
      display: block;
      padding: 0 10px;
      font-size: 24px;
    }
    @include mq(">midWidth") {
      &:nth-child(2) {
        &.is_animate {
          transition-delay: 0.1s;
        }
      }
      &:nth-child(3) {
        &.is_animate {
          transition-delay: 0.2s;
        }
      }
      &:nth-child(4) {
        &.is_animate {
          transition-delay: 0.3s;
        }
      }
      &:nth-child(5) {
        &.is_animate {
          transition-delay: 0.4s;
        }
      }
    }
  }
  &__nav_link_wrap {
    @include mq(">midWidth") {
      .header__nav_link_each {
        &:nth-child(2) {
          &.is_animate {
            transition-delay: 0.1s;
          }
        }
        &:nth-child(3) {
          &.is_animate {
            transition-delay: 0.2s;
          }
        }
        &:nth-child(4) {
          &.is_animate {
            transition-delay: 0.3s;
          }
        }
        &:nth-child(5) {
          &.is_animate {
            transition-delay: 0.4s;
          }
        }
      }
    }
    @include mq("<midWidth") {
      width: 330px;
      display: flex;
      flex-wrap: wrap;
      .header__nav_link_each {
        // width: 50%;
        width: 100%;
      }
      .header__nav_link_each {
        &:nth-last-child(-n + 2) {
          // margin-bottom: 0;
        }
        &:nth-child(-n + 2) {
          margin-bottom: 23px;
        }
      }
    }
  }
  &__nav_bottom_block {
    display: flex;
    @include mq(">midWidth") {
      margin-right: 40px;
    }
    @include mq("<midWidth") {
      position: relative;
      margin-right: 30px;
      margin-top: 72px;
    }
  }
  &__nav_lang_block {
    @include mq(">midWidth") {
      display: none;
    }
    @include mq("<midWidth") {
      font-size: 18px;
      // padding-top: 21px;
      // border-top: 1px solid $white;
      display: inline-block;
    }
  }
  &__nav_lang_link {
    &:not(:first-child) {
      @include mq(">midWidth") {
        margin-left: 25px;
      }

      @include mq("<midWidth") {
        // margin-left: 12px;
      }
    }
    &.is_current {
      color: $blue;
    }
  }
  &__nav_sns_block {
    display: inline-block;
    position: absolute;
    @include mq(">midWidth") {
      bottom: 18px;
      right: 40px;
    }
    @include mq("<midWidth") {
      right: 0;
      bottom: 0px;
      display: flex;
      align-items: center;
    }
  }

  // left_link
  &__left_link {
    z-index: 10;
    @include mq(">midWidth") {
      transform: translateX(-100px);
    }
    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.6s;
        position: fixed;
      }
    }
  }
  &__left_list {
    position: absolute;
    margin-left: 34px;
    top: (21.5 - 10) + px;
    width: 160px;

    opacity: 1;
    visibility: visible;
    transition: visibility 0.6s, opacity 0.6s ease-out;
    &.is_fade {
      opacity: 0;
      visibility: hidden;
      transition: visibility 0.6s, opacity 0.6s ease-out;
    }

    @include mq("<midWidth") {
      display: none;
    }
  }
  &__left_each {
    margin-bottom: 5px;
    @include font_avenir_B();
    font-size: 20px;
    line-height: 1.4;
    z-index: 10;
    &.-mt {
      margin-top: 20px;
    }
    &.-office {
      font-size: 14px;
    }
  }
  &__left_each_inner {
    margin-top: 10px;
    margin-left: 8px;
    margin-bottom: 14px;
    li {
      font-size: 16px;
      line-height: 1.2;
    }

    color: $gray;
    min-width: 110px;
    li {
      &::before {
        content: "-";
        padding-right: 6px;
      }
    }
  }

  // lang_block
  &__lang_block {
    position: fixed;
    z-index: 100;
    // margin-top: 21.5px;

    @include mq(">midWidth") {
      top: 35px;
      right: 38 + px;
    }
    @include mq("<midWidth") {
      top: 16px;
    }

    @include mq(">midWidth") {
      transform: translateX(100px);
    }
    @include mq("<midWidth") {
      display: none;
    }
    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.6s;
      }
    }
  }
  &__lang_link {
    display: block;
    text-align: center;
    // margin-top: 21.5px;
    font-size: 12px;
    font-weight: 400;
    &:not(:first-child) {
      margin-top: 13px;
    }
    color: $gray;
    &.is_current {
      color: $blue;
    }
  }

  // sns_block{
  &__sns_block {
    position: fixed;
    // bottom: 86.5px;
    bottom: 26 + $fvPadding + px;
    z-index: 100;
    opacity: 1;
    visibility: visible;
    transition: visibility 0.6s, opacity 0.6s ease-out;
    @include mq(">maxWidth") {
      right: $fvPadding + 30 + px;
    }
    @include mq(">midWidth") {
      transform: translateX(100px);
    }
    @include mq(">midWidth", "<maxWidth") {
      right: calc(6.3% - 20px);
    }
    @include mq("<midWidth") {
      display: none;
    }
    @include mq(">midWidth") {
    } //pc
    @include mq("<midWidth") {
    } //sp

    &.is_animate {
      @include mq(">midWidth") {
        transition-delay: 0.6s;
      }
    }
    &.is_fade {
      // opacity: 0;
      // visibility: hidden;
      // transition: visibility 0.6s, opacity 0.6s ease-out;
    }
  }
  &__sns_link {
    display: block;
    img {
      width: 15px;
      height: 15px;
      @include mq("<midWidth") {
        width: 22px;
        height: 22px;
      }
    }
    &:last-child {
      img {
        height: 10px;
        @include mq("<midWidth") {
          height: 15px;
        }
      }
    }
    &:not(:last-child) {
      @include mq(">midWidth") {
        margin-bottom: 16px;
      } //pc
      @include mq("<midWidth") {
        margin-right: 14px;
      } //sp
    }
  }
  .-navHyphen {
    @include mq("<midWidth") {
      display: inline-flex;
      margin-left: 8px;
      margin-right: 5px;
    }
  } //-navHyphe
}
