.common__item_list {
  box-sizing: border-box;
  @include mq(">midWidth") {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .common__item {
    @include mq(">midWidth") {
      margin-bottom: 40px;
      padding-left: 7.5px;
      padding-right: 7.5px;
    }
    @include mq("<midWidth") {
      margin-bottom: 25px;
    }
  }
  &.-col04 {
    .common__item {
      @include mq(">midWidth") {
        width: calc(100% / 4);
      }
    }
  } //col04
  &.-col03 {
    .common__item {
      @include mq(">midWidth") {
        width: calc(100% / 3);
      }
    }
  } //col03
  &.-col02 {
    .common__item {
      @include mq(">midWidth") {
        width: calc(100% / 2);
      }
    }
  } //col02
  &.-col01 {
    .common__item {
      @include mq(">midWidth") {
        width: calc(100% / 1);
      }
    }
  } //col01
  &__link {
    display: block;
  }
  &__eachtext {
    @include mq(">minWidth") {
      margin-top: 7px;
    } //pc

    @include mq("<minWidth") {
      margin-top: 5px;
    } //sp

    a {
      @include mq("<midWidth") {
        text-decoration: underline;
      }
    }
  }
  &__eachdate {
    color: $gray;
    @include mq("<midWidth") {
      font-size: 14px;
    }
    // @include mq(">midWidth") {
      margin-top: 16px;
    // }
  }
  &__more_link {
    color: $gray;
    margin-top: 15px;
    display: inline-block;
    @include underline;
  }
  .common__item_list__eachimg {
    @include mq("<minWidth") {
      margin-bottom: 15px;
    } //sp
  }
}
