body[id="member"] {
  .about_member {
    &__list_row {
      display: flex;
      flex-wrap: wrap;
      @include mq("<midWidth") {
        width: 80%;
        margin: auto;
      }
    }
    &__list_item {
      margin-bottom: 60px;
      @include mq(">midWidth") {
        width: calc((100% - 30px) / 3);
        &:nth-child(3n + 2) {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
    &__list_name {
      @include mq(">midWidth") {
        font-size: 19px;
      }
      @include mq("<midWidth") {
        text-align: center;
        color: $blue;
        font-size: 18px;
      }
    }
    &__list_pos {
      color: $gray;
      margin-top: 10px;
      @include mq("<midWidth") {
        text-align: center;
      }
    }
    &__list_projct_title {
      padding-bottom: 10px;
      border-bottom: 1px solid $border-color;
      color: $gray;
      @include mq(">midWidth") {
        margin: 36px 0 22px;
      }
      @include mq("<midWidth") {
        margin: 15px 0 15px;
      }
    }
    &__list_project_each {
      display: inline-block;
    }
    &__list_project_link {
      text-decoration: underline;
      font-size: 14px;
      @include lh_sp;
      @include lh_pc;
      &::after {
        content: "/";
        display: inline-block;
        padding: 0 5px;
      }
    }
    &__list_project {
      @include mq(">midWidth") {
        padding-top: 30px;
      }
      @include mq("<midWidth") {
        padding-top: 20px;
      }
    }
  }
  .about_member__list_img_block {
    img {
      @include mq("<midWidth") {
        margin-left: auto;
        margin-right: auto;
      }
    }
  } //about_member__list_img_block
  .about_member__list_text_block{
    margin-top: 16px;
  }
  .about_member__lead{
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.5;
  }
  .about_member__works{
    display: block;
    margin-top: 20px;
    @include mq("<midWidth") {
      text-align: center;
    }
  }
}
