body[id="about"] {
  .about_fv {
    @include mq("<midWidth") {
      margin: -55px 0 auto;
    }
    &__bg {
      background: center/cover no-repeat;
      background-image: url(/assets/front/img/about/fv_pc.jpg);
      width: 100%;
      height: 0;
      padding-top: get_per(526, 965);
      @include mq("<minWidth") {
        padding-top: get_per(230, 460);
      }
    }
  }
  .common__all_top_block{
    @include mq("<minWidth") {
      margin-top: 55px;
    }
  }
  .about_section {
    position: relative;
    margin-top: 89px;
    .about_award__list_block {
      margin-top: 3px;
    } //about_award__list_block
  }
  .common__main_title{
    line-height: 1.2;
  }
  .about_top__section {
    .about__info_list {
      margin-top: 40px;
    }
  }
  .about_profile__section{
    .common__right_block > .about_content__text:not(:last-child){
      @include mq(">minWidth") {
        margin-bottom: 70px;
      }
      @include mq("<minWidth") {
        margin-bottom: 30px;
      }
    }
    .-title{
      color: $gray;
    }
    .-snsLink{
      display: inline-block;
      transform: translateY(3px);
      margin-left: 10px;
      img{
        width: 18px;
        height: 18px;
      }
    }
    .-wrap{
      @include mq(">minWidth") {
        display: flex;
        margin-bottom: 80px;
        .-profImg{
          width: 24%;
          height: 24%;
        }
        p{
          margin-left: 40px;
        }
      }
      @include mq("<minWidth") {
        margin-bottom: 30px;
        .-profImg{
          margin: 0 auto 22px;
          width: 70%;
        }
      }
    }
  }
  .about_client__section {
    .about_content__text {
      @include mq("<midWidth") {
        &.is_sp {
          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .about_profile__text_link {
    color: $gray;
    display: inline-block;
    @include underline;
    margin-left: 1rem;
  }

  //awardの上余白調整設定
  $about_award__item_titlePosition: 45px;

  .about_award__section {
    @include mq(">minWidth") {
      padding-top: $about_award__item_titlePosition;
    }
    .about_award__item {
      display: flex;
      margin-bottom: 15px;
      &_left {
        @include mq(">minWidth") {
          display: none;
        }
        @include mq("<minWidth") {
          min-width: 60px;
          font-size: 14px;
          color: $gray;
          font-weight: 400;
          margin-top: 0.5em;
        }
      }
      &_right {
        display: flex;
        width: 100%;
        @include mq("<minWidth") {
          flex-wrap: wrap;
          margin-top: -0.3em;
        }
      }
      &_txt {
        @include lh_sp;
      }
      &:first-child {
        @include mq(">minWidth") {
          &:before {
            .about_award__item {
              &_title {
                position: relative;
                position: absolute;
                top: -$about_award__item_titlePosition;
                left: 0;
              }
            }
            &_aw {
              &:before {
                content: "Award";
              }
            } //_aw
            &_pj {
              &:before {
                content: "Project";
              }
            } //_pj
          } //about_award__item
        }
      } //first-child
    } //about_award__item
  } //about_award__section
  .about_award__text_list_each {
    position: relative;
    margin-left: (8 + 8) + px;
    line-height: 1.5;
    @include mq(">midWidth") {
      margin-bottom: 6px;
    } //pc
    @include mq("<maxWidth") {
      margin-bottom: 10px;
    } //sp

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -16px;
      width: 8px;
      height: 1px;
      background-color: $blue;
      @include mq(">minWidth") {
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px;
      } //pc

      @media screen and (max-width: 480px) {
        margin-top: 0.8em;
      } //sp
    }
  } //about_detail__text_list
  .common__layout_block{
    .mon__info{
      margin-top: 20px;
      .-flexbox{
        margin-top: 15px;
      }
    }
  }
}
