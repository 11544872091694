.contact_index,
.contact_form,
.contact_confirm,
.contact_error {
  .common__form {
    max-width: 754px;
  }
}

.contact_section {
  @include mq("<midWidth") {
    margin-top: 65px;
  }
  &__top_block {
    margin-bottom: 84px;
  }
  &__top_lead {
    @include text_default;
    // margin-bottom: 24px;
  }
  &__top_tel {
    margin-bottom: 10px;
    font-size: 24px;
    @include font_avenir_M;
  }
  &__top_text {
    @include mq("<midWidth") {
      font-size: 14px;
    }
  }
  &__top_span {
    padding-left: 8px;
    @include font_avenir_M;
    @include mq("<midWidth") {
      @include underline;
      text-decoration-thickness: 1px;
    }
  }
}
#contact {
  &[class="contact_complete"] {
    .common__intro {
      margin-bottom: 0;
    } //common__intro
  }
}
