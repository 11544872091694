body[id="recruit"] {
  &[class="recruit_index"] {
    .common__intro {
      margin-bottom: 6rem;
    }
    .common__layout_block.-contents.-default {
      &:not(:last-child) {
        @include mq(">midWidth") {
          margin-bottom: 2rem;
          border-bottom: 1px solid $border-color;
        }
        @include mq("<midWidth") {
          margin-bottom: 2rem;
          padding-bottom: 2rem;
        }
      }
    }
    .recruit__mainImg{
      display: flex;
      justify-content: space-between;
      margin-bottom: 75px;
      @include mq("<midWidth") {
        display: none;
      }
      .-item{
        width: calc(33.3% - 5px);
      }
    }
    .recruit__mainImgSp{
      display: flex;
      justify-content: space-between;
      margin-bottom: get_vw(60, 375);
      @include mq(">midWidth") {
        display: none;
      }
      .-item{
        height: get_vw(185, 375);
        &:nth-child(1){
          width: calc(64% - 2.5px);
          background-image: url("/assets/front/img/recruit/01_SP.jpg");
          background-size: cover;
        }
        &:nth-child(2){
          width: calc(36% - 2.5px);
          background-image: url("/assets/front/img/recruit/03_SP.jpg");
          background-size: cover;
        }
      }
    }
    .recruit_content {
      &__list_position {
        display: flex;
        flex-wrap: wrap;
        @include mq(">midWidth") {
          width: 35%;
          min-width: 200px;
        }
        @include mq("<midWidth") {
          line-height: 1;
          margin-bottom: 0.5rem;
        }
        .-en {
          width: 100%;
        } //en
        .-ja {
          @include font_tsukugo_D;
          margin-top: 0.8rem;
          font-size: 57%;
          color: $gray;
        } //-ja
      }

      &__list_detail {
        display: flex;
        width: 100%;
        @include mq(">midWidth") {
          align-items: center;
          padding-bottom: 2rem;
        }
        @include mq("<midWidth") {
          flex-wrap: wrap;
        }
        .common__main_title {
          margin-bottom: 0;
        }
      } //__list_detail
      &__list_link {
        position: relative;
        display: flex;
        &::after {
          display: block;
          content: url(/assets/front/img/common/arrow_right_blue.svg);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include mq(">minWidth") {
            height: 19px;
            width: 16px;
            right: 0;
            margin-top: -1rem;
          } //pc
          @include mq("<minWidth") {
            height: 1.3rem;
            width: 1rem;
            right: 10px;
            margin-top: (1.3/2) + rem;
          } //sp
        }
      }
      &__list_text {
        @include mq(">midWidth") {
          flex: 1;
          padding-right: 3rem;
          padding-left: 2rem;
        }
        @include mq("<midWidth") {
          width: 85%;
          margin-top: 1rem;
        }
      } //__list_text
      .-isFalse {
        .recruit_content__list_link {
          &::after {
            display: none;
          }
        }
        .recruit_content__list_detail {
          align-items: center;
        }
      } //isFalse
    }
    &[data-lang="en"],&[data-lang="cn"]{
      .recruit_content__list_detail{
        .-ja{
          visibility: hidden;
        }
      }
    }
  } //&[class="recruit_index"]
  &[class="recruit_detail"]{
    .common__title_block {
      @include mq(">midWidth") {
        margin-right: 145px;
      }
    }
  }
  &[class="recruit_detail"],
  &[class="recruit_confirm"] {
    .-posRelative {
      position: relative;
    }
    .common__title_block {
      @include mq("<midWidth") {
        margin-bottom: 0;
      }
    }
    .recruit_detail__top_link {
      font-size: 18px;
      color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;

      @include font_tsukugo_M;
      @include mq(">midWidth") {
        position: absolute;
        right: 0;
        top: 17px;
        margin-right: (5 + 16) + px;
      }
      @include mq("<midWidth") {
        font-size: 14px;
        margin-top: auto;
        padding-right: 16px;
        display: inline-flex;
        margin-left: auto;
        white-space: nowrap;
      }
      &:after {
        content: url(/assets/front/img/common/arrow_down_blue.svg);
        display: inline-block;
        transform: translate(10px, 0);
        position: absolute;
        height: 16px;
        width: 16px;
        position: relative;
        background-size: contain;
        right: -5px;
        @include mq("<midWidth") {
          height: 13px;
          width: 13px;
        }
      }
    } //.recruit_detail__top_link
    .recruit_titleTopLinkWrap {
      @include mq("<midWidth") {
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
      }
    }

    .common__form_list_each--multiple {
      @include mq("<midWidth") {
        flex-wrap: wrap;
      }
      .common__form_list_title {
        @include mq("<midWidth") {
          margin-bottom: 1rem;
        }
      }
      .common__form_inner_each {
        display: block;
      }
    }
    .common__form_list_inner {
      max-width: 100%;
      &.-adjust {
        display: flex;
        flex-wrap: wrap;
        @include mq(">midWidth") {
          margin-left: -10px;
          margin-right: -10px;
        }
        .common__form_inner_each {
          @include mq(">midWidth") {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .-half_pc {
          @include mq(">midWidth") {
            width: calc(100% / 2);
            padding-top: 0;
          }
        }
      } //adjust
    } //common__form_list_inner
    .common__title_wrap {
      @include mq("<midWidth") {
        margin-bottom: 1rem;
      }
    }
    .recruit_detail__text_list_each {
      line-height: 2;
      position: relative;
      margin-left: (8 + 8) + px;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: -16px;
        width: 8px;
        height: 1px;
        background-color: $blue;
        @include mq(">minWidth") {
          top: 1em;
          margin-top: -2.5px;
        } //pc

        @media screen and (max-width: 480px) {
          margin-top: 0.9em;
        } //sp
      }
    } //recruit_detail__text_list
    .recruit_content__text {
      &:not(:last-child) {
        margin-bottom: 68px;
      }
    } //recruit_content__text
  } //&[class="recruit_detail"]
  .recruit_content__main_list {
    border-top: 1px solid #a6b7bf;
    padding-top: 2rem;
  } //recruit_content__main_list
  .-application {
    .recruit_content__text {
      @include mq("<maxWidth") {
        margin-bottom: 10px !important;
      } //sp
    } //recruit_content__text
  } //-application
  &[class="recruit_detail"] {
    .recruit__info_list {
      @include mq(">midWidth") {
        width: 69%;
        padding-left: 3%;
      }
    }
  }
  &[class="recruit_confirm"] {
    .common__form_list_each {
      padding-top: 32px;
      &:first-child {
        padding-top: 0;
      } //first-child
    }
    .common__form_button_box {
      margin-top: 48px;
    } //.common__form_button_box
  } //  &[class="recruit_confirm"] {

  &[class="recruit_complete"] {
    .common__intro {
      margin-bottom: 0;
    } //common__intro
  }
}
